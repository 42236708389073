import { useState, useEffect } from "react";
import { Frame, Loading, Header, Select } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Form3 = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDados((values) => ({ ...values, [name]: value }));
  };

  const setData = async () => {
    await setDoc(
      doc(db, "desconto2024", uid),
      { ...dados, status: "p" },
      { merge: true }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setData().then(() => navigate("../home"));
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "desconto2024", uid));

    const data = qry.data();
    const status = data?.status;

    if (!status || status === "e") {
      setDados(data);
      setLoading(false);
    } else navigate("../home");
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header />
      <Frame
        body={
          <form autoComplete="off" onSubmit={handleSubmit} noValidate>
            <div className="text-secondary text-center">
              Selecione as linhas que você utilizará o serviço.
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Select
                  id="linha1"
                  label="Linha 1"
                  onChange={handleChange}
                  size="col-sm-12"
                  value={dados.linha1}
                  options={
                    <>
                      <option value=""></option>
                      <option value="271">Pelotas x Rio Grande</option>
                      <option value="271A">Pelotas x Cassino</option>
                      <option value="353">Pelotas x Porto Alegre</option>
                      <option value="622">Pelotas x Canguçu</option>
                      <option value="269">Pelotas x Piratini</option>
                      <option value="261">Pelotas x Chui</option>
                      <option value="408">Rio Grande x Chui</option>
                      <option value="1636">Canguçu x Piratini</option>
                    </>
                  }
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <Select
                  id="linha2"
                  label="Linha 2"
                  onChange={handleChange}
                  size="col-sm-12"
                  value={dados.linha2}
                  options={
                    <>
                      <option value=""></option>
                      <option value="271">Pelotas x Rio Grande</option>
                      <option value="271A">Pelotas x Cassino</option>
                      <option value="353">Pelotas x Porto Alegre</option>
                      <option value="622">Pelotas x Canguçu</option>
                      <option value="269">Pelotas x Piratini</option>
                      <option value="261">Pelotas x Chui</option>
                      <option value="408">Rio Grande x Chui</option>
                      <option value="1636">Canguçu x Piratini</option>
                    </>
                  }
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <Select
                  id="linha3"
                  label="Linha 3"
                  onChange={handleChange}
                  size="col-sm-12"
                  value={dados.linha3}
                  options={
                    <>
                      <option value=""></option>
                      <option value="271">Pelotas x Rio Grande</option>
                      <option value="271A">Pelotas x Cassino</option>
                      <option value="353">Pelotas x Porto Alegre</option>
                      <option value="622">Pelotas x Canguçu</option>
                      <option value="269">Pelotas x Piratini</option>
                      <option value="261">Pelotas x Chui</option>
                      <option value="408">Rio Grande x Chui</option>
                      <option value="1636">Canguçu x Piratini</option>
                    </>
                  }
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 d-grid">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Linhas"
      />
    </>
  );
};

export default Form3;
