import { useNavigate } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import Logo from "../assets/logo2.png";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { useState } from "react";
import { Loading } from "../components";
import { Nome } from "../components/Formatar";

const Header = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const logoutFirebase = () => {
    setLoading(true);

    signOut(auth).then(() => {
      navigate("../login");
      setLoading(false);
    });
  };

  const UserProfile = () => {
    return (
      <>
        <img
          alt={Nome(props.nome)}
          src={props.foto}
          width={25}
          height={25}
          className="d-inline-block align-top rounded me-2"
        />
        {Nome(props.nome)}
      </>
    );
  };

  return (
    <>
      {loading && <Loading />}
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className="shadow-sm border-bottom d-print-none bg-white text-capitalize"
      >
        <Container>
          <Navbar.Brand href="/home">
            <img
              src={Logo}
              height="40"
              className="d-inline-block align-top"
              alt="Expresso Embaixador"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {props.nome && props.foto && (
                <Nav.Link className="border-bottom d-block d-lg-none">
                  <UserProfile />
                </Nav.Link>
              )}

              <Nav.Link href="/home">Início</Nav.Link>
              
              <Nav.Link href="/contato">Fale Conosco</Nav.Link>
            </Nav>

            <Nav>
              {props.nome && props.foto ? (
                <NavDropdown
                  className="d-none d-lg-block"
                  title={<UserProfile />}
                >
                  <NavDropdown.Item onClick={logoutFirebase}>
                    Sair
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link
                  className="d-none d-lg-block"
                  onClick={logoutFirebase}
                >
                  Sair
                </Nav.Link>
              )}

              <Nav.Link className="d-block d-lg-none" onClick={logoutFirebase}>
                Sair
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
