const Input = (props) => {
  return (
    <>
      {props.label && (
        <label htmlFor={props.id} className="form-label text-start p-0">
          {props.label}
        </label>
      )}

      <input
        type={props.type || "text"}
        className={"form-control " + props.className}
        id={props.id}
        name={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        accept={props.accept}
        min={props.min}
        max={props.max}
        disabled={props.disabled ? true : false}
        autoFocus={props.autoFocus ? true : false}
        autoComplete="off"
      />
      <div className="invalid-feedback text-start">{props.erro}</div>
      {props.url && (
        <div className="m-2">
          <a href={props.url} target="_blank" rel="noreferrer" className="dark">
            🔎 Abrir documento enviado
          </a>
        </div>
      )}
    </>
  );
};

export default Input;
